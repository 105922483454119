export const IS_PRODUCTION = process.env.NODE_ENV !== 'development'

export function arrayToObject (arr, key, transform) {
	return arr && key ? Object.assign({}, ...arr.map(item => ({[item[key]]: transform(item)}))) : {}
}

export function log() {
	if (IS_PRODUCTION) {
		console.log(...arguments)
	}
}