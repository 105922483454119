import React, { useEffect, useState } from "react"
import useIntersection from "../hooks/useIntersection"
import { log } from "./helpers"

export const BRAND = "Bard Scotland"

export function getTags () {
	const isBrowser = typeof window !== 'undefined'
	const track = isBrowser ? window.gtag || (() => null) : (() => null)
	return { track }
}

export function trackPageView(location) {
	const { track } = getTags()
	const page_location = location ? location.href : window.location.href

	track("event", "page_view", { 
		page_location,
		event_callback: (e) => {
			log('page_view', e)
		}
	});
}

export function trackViewProduct(data={}) {
	const { track } = getTags()

	// GA4 implementation
	// https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag#select_an_item_from_a_list
	track("event", "view_item", {
		currency: data.currency,
		value: data.price,
		price: data.price,
		items: [data],
		products: [data],
		event_callback: (e) => {
			log('view_item', e)
		}
	});
}

export function trackNewsletterSubscribe(data={}) {
	const { track } = getTags()

	track("event", "subscribe", {
		...data,
		event_callback: (e) => {
			log('subscribe', e)
		}
	})
}

export function useLeadImpression(data={}, flag) {
	const [hasLead, setLead] = useState(false)
	const track = () => {
		if (!hasLead) {
			trackViewProduct(data)
			setLead(true)
			log('track lead!')
		} else {
			log('already sent lead')
		}
	}
	useEffect(() => {
		if (flag) {
			track()
		}
	}, [flag])
	return [hasLead, track]
}

export function LeadPixel({ whileInView }) {
	const [ref, inView] = useIntersection({
		threshold: 1
	}, true)
	useEffect(() => {
		if (inView) {
			whileInView()
		}
	}, [inView])
	return <div ref={ref} style={{ height: 1, width: '100%' }} />
}

export function useTrackViewProduct(data={}, deps=[]) {

	useEffect(() => {
		if (data.price) {
			trackViewProduct(data)
		}
		return
	}, [...deps])
}

export function trackAddToCart(data={}) {

	const { track } = getTags()

	// GA4 implementation
	// https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag#add_or_remove_an_item_from_a_shopping_cart
	track("event", "add_to_cart", {
		currency: data.currency,
		value: data.value,
		price: data.value,
		items: [data],
		products: [data],
		event_callback: (e) => {
			log('add_to_cart', e)
		}
	});

	// Facebook
	// https://developers.facebook.com/docs/meta-track/reference
	// _fbq('track', 'AddToCart', { 
	// 	content_name: data.item_name,
	// 	content_ids: [data.item_id],
	// 	currency: data.currency, 
	// 	value: data.price 
	// })
}

export function trackRemoveFromCart(data={}) {

	const { track } = getTags()

	// GA4 implementation
	// https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag#add_or_remove_an_item_from_a_shopping_cart
	track("event", "remove_from_cart", {
		currency: data.currency,
		value: data.value,
		price: data.value,
		items: [data],
		products: [data],
		event_callback: (e) => {
			log('remove_from_cart', e)
		}
	});

}

export function trackViewCart(data={}) {
	const { track } = getTags()

	// https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag#add_or_remove_an_item_from_a_shopping_cart
	track("event", "view_cart", {
		...data,
		event_callback: (e) => {
			log('view_cart', e)
		}
	});
}

export function useTrackViewCart(data={}, deps=[]) {
	useEffect(() => {
		if (data?.value) {
			trackViewCart(data)
		}
		return
	}, [...deps])
}

export function trackViewItemList(data={}) {
	const { track } = getTags()

	// GA4 implementation
	// https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag
	track("event", "view_item_list", {
		...data,
		event_callback: (e) => {
			log('view_item_list', e)
		}
	});
}

export function useTrackViewItemList(data={}, deps=[]) {
	useEffect(() => {
		if (data?.item_list_id) {
			trackViewItemList(data)
		}
		return
	}, [...deps])
}

export function trackSelectItem(data={}) {
	const { track } = getTags()

	// GA4 implementation
	// https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag
	track("event", "select_item", {
		...data,
		event_callback: (e) => {
			log('select_item', e)
		}
	});
}

export function trackSearch(data={}) {
	const { track } = getTags()

	// GA4 implementation
	// https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag
	track("event", "view_search_results", {
		search_term: data.search_term,
		event_callback: (e) => {
			log('view_search_results', e)
		}
	});
}