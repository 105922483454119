import React from 'react';
import { Script } from 'gatsby';
import { IS_PRODUCTION } from '../components/helpers';
const GA4_ID = process.env.GATSBY_GA4_ID
// const GTM_ID = process.env.GATSBY_GTM_ID

// https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-script/
export default function RootElement ({ children }) {
  return (
    <>
			{GA4_ID && IS_PRODUCTION && <GoogleAnalytics />}
      {children}
    </>
  );
};

export function GoogleAnalytics() {
	return (
		<>
			<Script src={`https://www.googletagmanager.com/gtag/js?id=${GA4_ID}`} />
			<Script>
				{`
					window.dataLayer = window.dataLayer || [];
					function gtag(){dataLayer.push(arguments);}
					gtag('js', new Date());
					gtag('config', '${GA4_ID}');
				`}
			</Script>
		</>
	)
}

// export function FacebookPixels() {
// 	return (
// 		<>
// 			<Script />
// 		</>
// 	)
// }