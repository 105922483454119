import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback
} from 'react'
import { createClient, Provider as UrqlProvider } from 'urql'
import useLocalStorage from '../hooks/useLocalStorage'
import { log } from '../components/helpers'

const CART_ID_KEY = `bard_cart_id_v1`
const COUNTRY_KEY = `bard_country_code_v1`

const CART_FRAGMENT = `
	id
	checkoutUrl
	cost {
		totalAmount {
			amount
			currencyCode
		}
	}
	lines(first: 200) {
		edges {
			node {
				id
				quantity
				merchandise {
					... on ProductVariant {
						id
						price {
							amount
							currencyCode
						}
						product {
							title
							handle
							vendor
							featuredImage {
								url
								width
								height
							}
						}
						selectedOptions {
							name
							value
						}
						quantityAvailable
					}
				}
			}
		}
	}
`

const API_VERSION = '2024-01' // 2022-10

const urqlClient = createClient({
  url: `https://${process.env.GATSBY_SHOPIFY_STORE_URL}/api/${API_VERSION}/graphql.json`,
  fetchOptions: {
    headers: {
      'X-Shopify-Storefront-Access-Token':
        process.env.GATSBY_STOREFRONT_ACCESS_TOKEN
    }
  }
})

export async function shopifyQuery(query, variables) {
  const data = await urqlClient.query(query, variables).toPromise()

  return data
}

export async function shopifyMutation(mutation, variables) {
  const data = await urqlClient.mutation(mutation, variables).toPromise()

  return data
}

export function LiveDataProvider({ children }) {
  return <UrqlProvider value={urqlClient}>{children}</UrqlProvider>
}

export const StoreContext = createContext({})

export function useStoreContext() {
  return useContext(StoreContext)
}

export default function StoreProvider({ children }) {
  const [localCartId, setLocalCartId, cartIdReady] =
    useLocalStorage(CART_ID_KEY)
  // TODO: country selection
  // eslint-disable-next-line
  const [localCountryCode, setLocalCountryCode, countryCodeReady] =
    useLocalStorage(COUNTRY_KEY, 'GB')
  // Helper to trigger things only when local storage is ready
  const localStorageReady = cartIdReady && countryCodeReady
  const [cart, setCart] = useState({})
  const [shopInfo, setShopInfo] = useState({})
  const [liveProducts, setLiveProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const [didJustAddToCart, setDidJustAddToCart] = useState(false)

  const createCart = (countryCode) => {
    log('running query: createCart()')
    shopifyMutation(`
			mutation {
				cartCreate(
					input: {
						attributes: { key: "cart_attribute", value: "This is a cart attribute" }
						buyerIdentity: {
							countryCode: ${countryCode}
						}
					}
				) {
					cart {
						${CART_FRAGMENT}
					}
				}
			}
		`).then((res) => {
      if (!res?.error) {
        const cartData = res.data?.cartCreate?.cart
        setCart(cartData)
        setLocalCartId(cartData?.id)
      } else {
        console.error(res.error)
      }
    })
  }

  const fetchCart = (cartId, callback) => {
    log('running query: fetchCart()')
    shopifyQuery(`
			{
				cart (
					id: "${cartId}"
				) {
					${CART_FRAGMENT}
				}
			}
		`).then((res) => {
      if (!res?.error) {
        const cartData = res.data?.cart
        if (cartData) {
          setCart(cartData)
          setLocalCartId(cartData?.id)
        }
        callback && callback(cartData)
      } else {
        console.error(res.error)
      }
    })
  }

  const addLineItem = (variantId, callback) => {
    log('running query: addLineItem()')

    const ADD_LINE_ITEM_QUERY = `
			mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
				cartLinesAdd(cartId: $cartId, lines: $lines) {
					cart {
						${CART_FRAGMENT}
					}
					userErrors {
						field
						message
					}
				}
			}
		`

    const variables = {
      cartId: cart.id,
      lines: [
        {
          merchandiseId: variantId,
          quantity: 1
        }
      ]
    }

    setLoading(true)

    shopifyMutation(ADD_LINE_ITEM_QUERY, variables)
      .then((res) => {
        if (!res?.error) {
          const cartData = res.data?.cartLinesAdd?.cart
          setCart(cartData)
          setLoading(false)
          setDidJustAddToCart(true)
          callback && callback(cartData)
          setTimeout(() => setDidJustAddToCart(false), 3000)
        } else {
          console.error(res.error)
          callback && callback(null, res.error)
          setLoading(false)
        }
      })
      .catch((error) => {
        console.error(error)
        callback && callback(null, error)
        setLoading(false)
      })
  }

  const updateLineItem = (lineItemId, quantity, callback) => {
    log('running query: updateLineItem()')

    const UPDATE_LINE_ITEM_QUERY = `
			mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
				cartLinesUpdate(cartId: $cartId, lines: $lines) {
					cart {
						${CART_FRAGMENT}
					}
					userErrors {
						field
						message
					}
				}
			}
		`

    const variables = {
      cartId: cart.id,
      lines: [
        {
          id: lineItemId,
          quantity
        }
      ]
    }

    shopifyMutation(UPDATE_LINE_ITEM_QUERY, variables)
      .then((res) => {
        if (!res?.error) {
          const cartData = res.data?.cartLinesUpdate?.cart
          setCart(cartData)
          setLoading(false)
          setDidJustAddToCart(true)
          callback && callback(cartData)
          setTimeout(() => setDidJustAddToCart(false), 3000)
        } else {
          console.error(res.error)
          callback && callback(null, res.error)
          setLoading(false)
        }
      })
      .catch((error) => {
        console.error(error)
        callback && callback(null, error)
        setLoading(false)
      })
  }

  const removeLineItem = (lineItemId) => {
    updateLineItem(lineItemId, 0)
  }

  const fetchLiveData = (country) => {
    log('running query: fetchLiveData()')
    setLiveProducts([])
    // const handle = "all-active-products-internal"
    shopifyQuery(`
			query ProductsWithPrices @inContext(country: ${country}) {
				products(first: 250) {
					nodes {
						... on Product {
							id
							title
							totalInventory
							priceRange {
								maxVariantPrice {
									amount
									currencyCode
								}
								minVariantPrice {
									amount
									currencyCode
								}
							}
							variants(first: 32) {
								nodes {
									id
									quantityAvailable
									availableForSale
									price {
										amount
										currencyCode
									}
									compareAtPrice {
										amount
										currencyCode
									}
								}
							}
						}
					}
				}
				# shop {
				# 	shipsToCountries
				# }
			}
		`).then((res) => {
      if (!res?.error) {
        setShopInfo(res.data?.shop)
        setLiveProducts(res.data?.products?.nodes)
      } else {
        console.error(res.error)
      }
    })
  }

  // Initialise
  const initialiseShop = () => {
    if (localStorageReady) {
      if (localCartId) {
        fetchCart(localCartId, (existingCart) => {
          if (!existingCart) {
            createCart(localCountryCode)
          }
        })
      } else {
        createCart(localCountryCode)
      }

      fetchLiveData(localCountryCode)
    }
  }
  // eslint-disable-next-line
  useEffect(initialiseShop, [localStorageReady])

  // Fetch collection data for sort order
  const fetchCollection = async ({ handle, country, sortKey, reverse }) => {
    if (!handle) {
      console.warn(`No handle passed to fetchCollection()`)
    }

    if (!country) {
      console.warn(
        `No country code passed to fetchCollection(). You can get this from the 'localCountryCode' context variable.`
      )
      return false
    }

    const variables = {
      handle,
      country,
      sortKey,
      reverse
    }

    try {
      const request = await shopifyQuery(
        `
        query Collection($country: CountryCode, $handle: String!, $sortKey: ProductCollectionSortKeys, $reverse: Boolean) @inContext(country: $country) {
          collection(handle: $handle) {
            products(first: 250, sortKey: $sortKey, reverse: $reverse) {
              nodes {
                id
              }
            }
          }
        }
      `,
        variables
      )

      if (request?.error) {
        console.log(request)
        return false
      }

      return request
    } catch (error) {
      console.log(error)
      return false
    }
  }

  // -------------------------------
  // Helpers

  const resolveProductData = useCallback(
    (products) => {
      const productsWithPrices = products.map((p) => {
        const liveProductMatch = liveProducts?.find(
          (item) => item.id === p.shopifyId
        )

        if (liveProductMatch) {
          const variants = p.variants?.map((v) => {
            const liveVariantMatch = liveProductMatch.variants?.nodes?.find(
              (item) => item.id === v.shopifyId
            )
            return liveVariantMatch
              ? {
                  ...v,
                  ...liveVariantMatch
                }
              : v
          })
          return {
            ...p,
            ...liveProductMatch,
            variants
          }
        } else {
          return p
        }
      })

      return productsWithPrices
    },
    [liveProducts]
  )

  return (
    <StoreContext.Provider
      value={{
        cart,
        addLineItem,
        updateLineItem,
        removeLineItem,
        loading,
        didJustAddToCart,
        shopInfo,
        liveProducts,
        localCountryCode,
        resolveProductData,
        fetchCollection
      }}
    >
      <LiveDataProvider>{children}</LiveDataProvider>
    </StoreContext.Provider>
  )
}
